import i18n from "@/i18n";

/**
 * request 상태를 표시하는 문자열을 반환한다.
 */
export function getRequestStatusText(status) {
  switch (status) {
    case "APPROVED":
      return i18n.t("request.status.approved");
    case "REJECTED":
      return i18n.t("request.status.rejected");
    case "0":
      return i18n.t("request.status.succeeded");
  }
  return status ? i18n.t("request.status.failed") : undefined;
}

export function getRequestApproveStatusText(status) {
  switch (status) {
    case "APPROVED":
      return i18n.t("request.status.approved");
    case "REJECTED":
      return i18n.t("request.status.rejected");
    case "REVIEWED":
      return i18n.t("request.status.reviewed");
  }
  return status;
}
