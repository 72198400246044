<template>
  <div>
    <v-dialog v-model="rootDialog" name="rootDialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-if="icon" class="mr-2" v-bind="attrs" v-on="on">
          {{ icon }}
        </v-icon>
      </template>
      <v-card>
        <v-card-title class="headline grey lighten-2">
          Request Details
        </v-card-title>

        <v-card-text>
          <div>
            Request:
            <ul>
              <li>requestId: {{ requestApprove.request.id }}</li>
              <li>adminUserId: {{ requestApprove.request.adminUserId }}</li>
              <li>action: {{ requestApprove.request.action }}</li>
              <li>IP address: {{ requestApprove.request.ip }}</li>
              <li>attributes: {{ requestApprove.request.attributes }}</li>
              <li>requested at: {{ requestApprove.request.createdAt }}</li>
              <li>
                last updated at: {{ requestApprove.request.lastStatusAt }}
              </li>
            </ul>
          </div>
          <div>
            Approve:
            <ul>
              <li>type: {{ requestApprove.review ? "review" : "approve" }}</li>
              <li>statusCode: {{ requestApprove.statusCode }}</li>
              <li>comments: {{ requestApprove.comments }}</li>
              <li>updated at: {{ requestApprove.updatedAt }}</li>
            </ul>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="approvable" color="primary" text @click="onApprove"
            >{{ $t("request.approve") }}
          </v-btn>
          <v-btn v-if="rejectable" color="primary" text @click="onReject"
            >{{ $t("request.reject") }}
          </v-btn>
          <v-btn v-if="executable" color="primary" text @click="onExecute"
            >{{ $t("request.execute") }}
          </v-btn>
          <v-btn
            v-if="statusChangeable"
            color="primary"
            text
            @click="onChangeStatus"
            >{{ $t("changeStatus") }}
          </v-btn>
          <v-btn color="normal" text @click="rootDialog = false"
            >{{ $t("close") }}
          </v-btn>
        </v-card-actions>
      </v-card>

      <v-overlay :value="inProgress">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-dialog>

    <v-dialog v-model="approveDialog" name="approveInputDialog" width="400">
      <v-card>
        <v-card-title class="headline grey lighten-2"> Approve</v-card-title>
        <v-card-text>
          <v-textarea
            v-model="comments"
            name="approveComments"
            label="Approve comments"
            auto-grow
          >
          </v-textarea>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="normal" text @click="onApproveCancel()">Cancel</v-btn>
          <v-btn color="primary" text @click="onApproveOK()">Approve</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="rejectDialog" name="rejectInputDialog" width="400">
      <v-card>
        <v-card-title class="headline grey lighten-2"> Reject</v-card-title>
        <v-card-text>
          <v-textarea
            v-model="comments"
            name="rejectComments"
            label="Reject comments"
            auto-grow
          >
          </v-textarea>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="normal" text @click="onRejectCancel()">Cancel</v-btn>
          <v-btn color="primary" text @click="onRejectOK()">Reject</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="changeStatusDialog"
      name="changeStatusDialog"
      width="400"
    >
      <v-card>
        <v-card-title class="headline grey lighten-2"
          >Change Status</v-card-title
        >
        <v-card-text>
          <v-select
            v-model="selectedStatus"
            :items="changeStateTargets"
            filled
            label="Status"
          >
          </v-select>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="normal" text @click="onChangeStatusCancel()"
            >Cancel</v-btn
          >
          <v-btn color="primary" text @click="onChangeStatusOK()">Change</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  name: "RequestDetailDialog",
  props: {
    requestApprove: {
      type: Object,
      default: () => ({}),
    },
    icon: {
      type: String,
      default: () => "mdi-text-box-outline",
    },
  },
  i18n: {
    messages: {
      en: {
        changeStatus: "Change Status",
      },
      ko: {
        changeStatus: "상태 변경",
      },
    },
  },
  data: (vm) => ({
    rootDialog: false,
    approveDialog: false,
    rejectDialog: false,
    changeStatusDialog: false,
    inProgress: false,
    comments: "",
    approvable: false,
    executable: false,
    rejectable: false,
    statusChangeable: false,
    changeStateTargets: [
      {
        text: vm.$i18n.t("request.status.approved"),
        value: "APPROVED",
      },
      {
        text: vm.$i18n.t("request.status.rejected"),
        value: "REJECTED",
      },
    ],
    selectedStatus: "APPROVED",
  }),
  watch: {
    requestApprove: function (appr) {
      this.updateData(appr);
    },
    rootDialog: function (visible) {
      if (visible) {
        this.onShowDialog();
      }
    },
  },
  created() {
    this.updateData(this.requestApprove);
  },
  methods: {
    updateData(appr) {
      const { request } = appr;
      const finished = request.lastStatusCode === "0";
      const failed = !isNaN(request.lastStatusCode) && !finished;
      const noActionYet = !appr.statusCode;

      this.approvable =
        !finished &&
        !failed &&
        !appr.review &&
        (noActionYet || appr.statusCode === "REJECTED");
      this.rejectable =
        !finished &&
        !failed &&
        !appr.review &&
        (noActionYet || appr.statusCode === "APPROVED");
      this.executable = !appr.review && request.lastStatusCode === "APPROVED";
      this.statusChangeable = !appr.review && failed;
    },

    /**
     * 다이얼로그창이 표시되는 경우 호출
     */
    onShowDialog() {
      // 회람 요청이면서 아직 회람 전인 경우 회람 이벤트 전송
      const appr = this.requestApprove;
      if (appr.review && appr.statusCode !== "REVIEWED") {
        setTimeout(async () => {
          await this.onReview();
        }, 100);
      }
    },

    /**
     * 요청 승인 대화창 표시
     */
    onApprove() {
      this.comments = "";
      this.approveDialog = true;
    },

    /**
     * 요청 거절 대화창 표시
     */
    onReject() {
      this.comments = "";
      this.rejectDialog = true;
    },

    /**
     * 요청 실행
     */
    async onExecute() {
      const { requestId } = this.requestApprove;
      try {
        this.inProgress = true;
        await this.authPost(`/api/request/execute/${requestId}`);
        this.rootDialog = false;
        this.emitChangeStatus("FINISHED");
      } catch (e) {
        console.error(e);
      } finally {
        this.inProgress = false;
      }
    },

    /**
     * 요청 회람
     */
    async onReview() {
      const { requestId } = this.requestApprove;
      try {
        this.inProgress = true;
        await this.authPost(`/api/request/review/${requestId}`);
        this.emitChangeStatus("REVIEWED");
      } catch (e) {
        console.error(e);
      } finally {
        this.inProgress = false;
      }
    },

    /**
     * 요청 상태 변경
     */
    onChangeStatus() {
      this.changeStatusDialog = true;
    },

    /**
     * 요청 승인
     */
    async onApproveOK() {
      const { requestId } = this.requestApprove;
      const { comments } = this;
      try {
        this.inProgress = true;
        await this.authPost(`/api/request/approve/${requestId}`, { comments });
        this.approveDialog = false;
        this.rootDialog = false;
        this.emitChangeStatus("APPROVED");
      } catch (e) {
        console.error(e);
      } finally {
        this.inProgress = false;
      }
    },

    /**
     * 요청 승인창 닫기
     */
    onApproveCancel() {
      this.approveDialog = false;
      this.comments = "";
    },

    /**
     * 요청 거절
     */
    async onRejectOK() {
      const { requestId } = this.requestApprove;
      const { comments } = this;
      try {
        this.inProgress = true;
        await this.authPost(`/api/request/reject/${requestId}`, { comments });
        this.rejectDialog = false;
        this.rootDialog = false;
        this.emitChangeStatus("REJECTED");
      } catch (e) {
        console.error(e);
      } finally {
        this.inProgress = false;
      }
    },

    /**
     * 요청 거절창 닫기
     */
    onRejectCancel() {
      this.rejectDialog = false;
      this.comments = "";
    },

    /**
     * 상태 변경창 닫기
     */
    onChangeStatusCancel() {
      this.changeStatusDialog = false;
    },

    /**
     * 상태 변경
     */
    async onChangeStatusOK() {
      const { requestId } = this.requestApprove;
      const statusCode = this.selectedStatus;
      try {
        this.inProgress = true;
        await this.authPost(`/api/request/status/${requestId}`, { statusCode });
        this.changeStatusDialog = false;
        this.rootDialog = false;
        this.emitChangeStatus(statusCode);
      } catch (e) {
        console.error(e);
      } finally {
        this.inProgress = false;
      }
    },

    /**
     * 요청 상태가 변경된 경우 이벤트 발생
     */
    emitChangeStatus(status) {
      this.$emit("change-status", {
        source: this.requestApprove,
        status,
      });
    },
  },
};
</script>

<style scoped></style>
