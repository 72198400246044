<template>
  <v-container id="test" fluid tag="section">
    <v-row>
      <v-col cols="auto">
        <v-card-actions class="pa-0">
          <v-spacer />
          <v-btn color="success" min-width="100" @click="refreshData">
            <v-icon dense>mdi-refresh</v-icon>
            {{ $t("refresh") }}
          </v-btn>
          <v-switch
            v-model="includeDone"
            :label="$t('includeDone')"
            dense
          ></v-switch>
        </v-card-actions>
      </v-col>
    </v-row>

    <v-data-table
      :headers="headers"
      :items="items"
      :item-class="itemClass"
      :items-per-page="itemsPerPage"
      :sort-by="[]"
      :sort-desc="[]"
      dense
      multi-sort
    >
      <template v-slot:item.request.lastStatusCode="{ item }">
        <v-chip
          v-if="item.request.lastStatusCode"
          :color="getRequestStatusColor(item.request.lastStatusCode)"
          dark
          label
        >
          {{ getRequestStatusText(item.request.lastStatusCode) }}
        </v-chip>
      </template>
      <template v-slot:item.statusCode="{ item }">
        <v-chip
          v-if="item.statusCode"
          :color="getApproveStatusColor(item.statusCode)"
          dark
          label
        >
          {{ getRequestApproveStatusText(item.statusCode) }}
        </v-chip>
      </template>
      <template v-slot:item.actions="{ item }">
        <request-detail-dialog
          :request-approve="item"
          @change-status="onChangeStatus"
        >
        </request-detail-dialog>
      </template>
    </v-data-table>
    <v-snackbar v-model="snackbar" top centered>
      {{ notificationMessage }}
    </v-snackbar>
  </v-container>
</template>

<script>
import {
  getRequestApproveStatusText,
  getRequestStatusText,
} from "@/utils/request";
import RequestDetailDialog from "@/views/dashboard/pages/request/RequestDetailDialog";

export default {
  components: { RequestDetailDialog },
  i18n: {
    messages: {
      en: {
        includeDone: "Include Done",
      },
      ko: {
        includeDone: "완료 포함",
      },
    },
  },
  data: (vm) => ({
    itemsPerPage: 10,
    headers: [
      {
        text: vm.$i18n.t("request.requestId"),
        value: "requestId",
      },
      {
        text: vm.$i18n.t("request.requestBy"),
        value: "request.adminUserId",
      },
      {
        text: vm.$i18n.t("request.action"),
        value: "request.action",
      },
      {
        text: vm.$i18n.t("request.ip"),
        value: "request.ip",
      },
      {
        text: vm.$i18n.t("request.statusCode"),
        value: "request.lastStatusCode",
      },
      {
        text: vm.$i18n.t("request.approveStatus"),
        value: "statusCode",
      },
      {
        text: vm.$i18n.t("request.requestAt"),
        value: "request.createdAt",
      },
      {
        text: "-",
        value: "actions",
        sortable: false,
      },
    ],
    items: [],
    includeDone: false,
    snackbar: false,
    notificationMessage: "",
  }),
  mounted() {
    this.refreshData();
  },
  methods: {
    async refreshData() {
      try {
        const res = await this.authGet(
          `/api/request/approves?done=${this.includeDone}`
        );
        if (res.data.data) {
          this.items = res.data.data.filter((item) => item.request);
        }
      } catch (e) {
        const { response } = e;
        if (response) {
          this.showNotification(response.data);
        } else {
          this.showNotification(e);
        }
      }
    },

    /**
     * 승인/회람 여부에 따라 사용할 스타일 클래스 반환
     */
    itemClass(item) {
      return item.review ? "review-item" : "approve-item";
    },

    getRequestStatusText(status) {
      return getRequestStatusText(status);
    },

    getRequestStatusColor(status) {
      switch (status) {
        case "0":
          return "blue";
        case "APPROVED":
          return "green";
        case "REJECTED":
          return "purple";
      }
      return status ? "red" : undefined;
    },
    getApproveStatusColor(status) {
      switch (status) {
        case "ACCEPTED":
          return "green";
        case "REJECTED":
          return "red";
      }
      return undefined;
    },

    getRequestApproveStatusText(status) {
      return getRequestApproveStatusText(status);
    },

    showNotification(msg) {
      this.notificationMessage = msg;
      this.snackbar = true;
    },

    async onChangeStatus() {
      await this.refreshData();
    },
  },
};
</script>

<style>
.review-item {
  background-color: rgba(215, 215, 255, 0.3);
}

.approve-item {
  background-color: rgba(255, 255, 215, 0.3);
}
</style>
